<template>
  <div>
    <v-card class="pa-4">
      <v-row>
        <v-col cols="12">
          <h1>Sales Product Report</h1>
        </v-col>
      </v-row>
      <v-row class="report-wrap">
        <v-col
          md="2"
          sm="12"
          class="d-flex align-end"
          cols="12">
          <v-select
            v-model="query.brand"
            label="เลือก Brand"
            :items="allBrand"
            outlined
            dense
            class="mr-2"
            hide-details />
        </v-col>
        <v-col
          md="5"
          sm="12"
          cols="12"
          class="d-flex align-end">
          <date-picker
            v-model="dateSelected"
            small />
        </v-col>
        <v-col
          md="5"
          sm="12"
          class="d-flex align-end"
          cols="12">
          <search-by-tags
            v-model="query"
            append-icon="mdi-magnify"
            tags-width="300px"
            :tags="tags"
            @on-search="fetchReportSummary()" />
          <v-btn
            color="success"
            small
            class="ml-1"
            @click="exportCsv">
            csv
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="mt-4">
      <v-data-table
        hide-default-footer
        :headers="headers"
        :items="items"
        item-key="skuId"
        :items-per-page="-1"
        class="elevation-1"
        :loading="fetching"
        :expanded.sync="expanded"
        show-expand
        dense>
        <template v-slot:[`item.image`]="{ item }">
          <div class="pa-2">
            <img
              :src="imgSrcCompute(item.images[0])"
              width="48"
              alt="">
          </div>
        </template>
        <template v-slot:[`item.all.sumPrice`]="{ item }">
          {{ item.all.sumPrice | showFullPriceFormat() }}
        </template>
        <template v-slot:[`item.code`]="{ item }">
          <span style="font-size: 12px;">{{ item.name }}</span> <br />
          <strong>{{ item.code }}</strong>
        </template>
        <template v-slot:[`item.options`]="{ item }">
          {{ item.color }} - {{ item.size }}
        </template>

        <template v-slot:expanded-item="{ headers: headerItem, item }">
          <td :colspan="2" />
          <td
            :colspan="headerItem.length - 2"
            class=" py-4">
            <v-data-table
              hide-default-footer
              :headers="saleDetailHeaders"
              :items="item.sales"
              :items-per-page="-1"
              dense>
              <template v-slot:[`item.sumPrice`]="{ item: { sumPrice } }">
                {{ sumPrice | showFullPriceFormat() }}
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import NewReportProvider from '@/resources/NewReportProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import SearchByTags from '@/components/SearchByTags.vue'
import { ExportToCsv } from 'export-to-csv'
import DatesPicker from '../components/DatesPicker.vue'

const defaultHeaders = [
    { text: 'IMG', value: 'image', width: 120, sortable: false },
    { text: 'Code', value: 'code', width: 360, align: 'left' },
    { text: 'Price', value: 'itemPrice', align: 'right' },
    { text: 'Options', value: 'options', width: 120, align: 'center', sortable: false },
    { text: 'Sales Value', value: 'all.sumPrice', align: 'center' },
    { text: 'All', value: 'all.amount', align: 'center' },
    { text: 'POS', value: 'pos.amount', align: 'center' },
    { text: 'Online', value: 'online.amount', align: 'center' },
    { text: 'Inter', value: 'inter.amount', align: 'center' }
  ]

const ProductAttributeService = new ProductAttributeProvider()
const NewReportService = new NewReportProvider()
export default {
  components: {
    DatePicker: DatesPicker,
    SearchByTags
  },
  data () {
    return {
      fetching: false,
      items: [],
      tags: [],
      query: {
        search: '',
        tags: [],
        tagOperation: 'OR',
        brand: 'all'
      },
      expanded: [],
      warehouses: [],
      allBrand: [],
      headers: [...defaultHeaders],
      saleDetailHeaders: [
        { text: 'Warehouse', value: 'warehouse.name', sortable: false },
        { text: 'amount', value: 'amount' },
        { text: 'sum price', value: 'sumPrice', align: 'right' }
      ],
      dateSelected: [this.$dayjs().subtract(6, 'day').format(), this.$dayjs().format()]
    }
  },
  async mounted () {
    await this.getBrand()
    await this.getTags()
  },
  methods: {
    async getBrand () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('brands', {
          limit: 999
        })

        this.allBrand = [
        {
          text: 'All',
          value: 'all'
        },
          ...data.results.map((brand) => ({
            text: brand.name,
            value: brand.name
          }))
        ]
      } catch (error) {
        console.error('getAttribute: ', error)
      }
    },
    imgSrcCompute (src) {
      return `${src}?fm=jpg&w=400` || 'https://via.placeholder.com/150'
    },
    getSale (warehouse, sales) {
      const sale = sales.find((s) => s.warehouse.name === warehouse.name)
      return sale.amount
    },
    async getTags () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('tags', {
          page: 1,
          itemsPerPage: 9999999,
          sortBy: ['id'],
          sortDesc: [true]
        })
        this.tags = data.results.map((r) => ({
          id: r.id,
          name: r.name,
          status: 'active'
        }))
      } catch (error) {
        console.error('getTags: ', error)
        this.setSnackbar({
          value: true,
          message: `[GET-TAGS-ERROR]: ${error.message}`,
          type: 'error'
        })
      }
    },
    async fetchReportSummary () {
      try {
        this.fetching = true
        const { data } = await NewReportService.getSalesProduct({
          brand: this.query.brand,
          search: this.query.search,
          tagOperation: this.query.tagOperation,
          tags: this.query.tags,
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })

        this.warehouses = data.warehouses
        this.items = data.results.map((re) => {
          const { sales } = re

          const all = {
            amount: re.pos.amount + re.online.amount + re.inter.amount,
            sumPrice: re.pos.sumPrice + re.online.sumPrice + re.inter.sumPrice,
            fullPrice: re.pos.fullPrice + re.online.fullPrice + re.inter.fullPrice,
            warehouse: {
              name: 'all',
              code: 'all'
            }
          }

          sales.push(re.online)
          sales.push(re.inter)
          sales.push(all)
          return {
            all,
            ...re,
            sales
          }
        })
      } catch (err) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${err.message}`,
          type: 'error'
        })
      } finally {
        this.fetching = false
      }
    },
    exportCsv () {
      const data = this.items.map((item) => {
        const payload = {}

        for (const warehouse of this.warehouses) {
          const found = item.sales.find((s) => s.warehouse.id === warehouse.id)
          payload[warehouse.name] = found?.amount || 0
        }

        return {
          code: item.code,
          name: item.name,
          tags: item.tags.join(', '),
          categories: item.categories.join(', '),
          price: item.itemPrice,
          color: item.color,
          size: item.size,
          all: item.all.amount,
          pos: item.pos.amount,
          online: item.online.amount,
          inter: item.inter.amount,
          ...payload
        }
      })

      const options = {
          filename:
            `product_sales_${this.$dayjs(this.dateSelected[0]).format('DD-MM-YYYY')}_${this.$dayjs(this.dateSelected[1]).format('DD-MM-YYYY')}`,
          showLabels: true,
          useKeysAsHeaders: true
        }

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
    }
  }
}
</script>

<style scoped>
.number-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border: 1px solid #cfcfcf;
  position: relative;
}
.number-box .box-title {
  position: absolute;
  font-size: 14px;
  padding: 0 5px;
  top: -10px;
  left: 5px;
  background-color: #fff;
}
</style>
