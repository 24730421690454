var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Sales Product Report")])])],1),_c('v-row',{staticClass:"report-wrap"},[_c('v-col',{staticClass:"d-flex align-end",attrs:{"md":"2","sm":"12","cols":"12"}},[_c('v-select',{staticClass:"mr-2",attrs:{"label":"เลือก Brand","items":_vm.allBrand,"outlined":"","dense":"","hide-details":""},model:{value:(_vm.query.brand),callback:function ($$v) {_vm.$set(_vm.query, "brand", $$v)},expression:"query.brand"}})],1),_c('v-col',{staticClass:"d-flex align-end",attrs:{"md":"5","sm":"12","cols":"12"}},[_c('date-picker',{attrs:{"small":""},model:{value:(_vm.dateSelected),callback:function ($$v) {_vm.dateSelected=$$v},expression:"dateSelected"}})],1),_c('v-col',{staticClass:"d-flex align-end",attrs:{"md":"5","sm":"12","cols":"12"}},[_c('search-by-tags',{attrs:{"append-icon":"mdi-magnify","tags-width":"300px","tags":_vm.tags},on:{"on-search":function($event){return _vm.fetchReportSummary()}},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('v-btn',{staticClass:"ml-1",attrs:{"color":"success","small":""},on:{"click":_vm.exportCsv}},[_vm._v(" csv ")])],1)],1)],1),_c('v-card',{staticClass:"mt-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"item-key":"skuId","items-per-page":-1,"loading":_vm.fetching,"expanded":_vm.expanded,"show-expand":"","dense":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_c('img',{attrs:{"src":_vm.imgSrcCompute(item.images[0]),"width":"48","alt":""}})])]}},{key:"item.all.sumPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.all.sumPrice))+" ")]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('br'),_c('strong',[_vm._v(_vm._s(item.code))])]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.color)+" - "+_vm._s(item.size)+" ")]}},{key:"expanded-item",fn:function(ref){
var headerItem = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":2}}),_c('td',{staticClass:" py-4",attrs:{"colspan":headerItem.length - 2}},[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.saleDetailHeaders,"items":item.sales,"items-per-page":-1,"dense":""},scopedSlots:_vm._u([{key:"item.sumPrice",fn:function(ref){
var sumPrice = ref.item.sumPrice;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(sumPrice))+" ")]}}],null,true)})],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }